import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';

import logo from 'img/help/integrations/drift-logo.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Drift?</SubTitle>
      <p>
        <strong>Drift is a live chat web application for support, sales and marketing teams</strong>
        . You can use it to onboard your users, help them with issues, or even upsell your products.
        It lets you track events, so you can monitor each contact in your web application.
        We&apos;ve created the integration that sends information from LiveSession to Drift. As a
        result, you&apos;ll have all the data about your users in one place.
      </p>
      <SubTitle className="mt-4">How does this integration work?</SubTitle>
      <p>
        This integration lets you add links to session replays from LiveSession to your Drift
        account.{' '}
        <strong>
          Thanks to this solution, you can open recordings of a specific user directly from Drift
          contact profile
        </strong>
        .
      </p>
      <p>
        Every time a new session will start, our code will send an event to Drift with a link to
        session replay.
      </p>
      <SubTitle className="mt-4">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if(drift && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
      drift.track('Session replay', {
        url: url
      });          
    }
})
}
</script>`}
      </Code>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Drift integration',
  metaDescription: 'Send LiveSession recordings to your Drift account',
  canonical: '/help/drift-integration/',
  logoStyle: { width: 120 },
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
